/**
 * @file Pinia helpers
 */
import window from '@@/bits/global'
import { Store as VuexStore } from 'vuex'

/**
 * Get the Vuex store that is initialized in the corresponding pack file.
 * Eg: For the dashboard_settings store, it is initialized in `packs/dashboard_settings.ts` by `window.app = vueInstance`
 *
 * @returns VuexStore
 * @example getVuexStore<DashboardSettingsState>()
 */
export function getVuexStore<T>(): VuexStore<T> | undefined {
  return window.app?.$store
}
